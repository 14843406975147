import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";


// home page
const Home = lazy(() => import("./pages/home/index"));
// shop page
const Shop = lazy(() => import("./pages/shop/index"));
const GeneralShopPage = lazy(() => import("./pages/shop/generalShopPage"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductPage = lazy(() => import("./pages/shop-product/ProductTabRight"));

// blog page
const Blogs = lazy(() => import("./pages/blog/index"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Return = lazy(() => import("./pages/other/Return"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const SizeGuide  = lazy(() => import("./pages/other/SizeGuide"))
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          ur: require("./translations/urdu.json"),
        },
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* Home Page */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />
                {/* Shop page */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={Shop}
                />
                {/* Currently All pages goes to this route but working with real data we will switch according to slug base */}
                <Route
                  path={process.env.PUBLIC_URL + "/general-shop-page"}
                  component={GeneralShopPage}
                />
                {/* Shop product page */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                  component={ProductPage}
                />
                {/* Blog page */}
                <Route
                  path={process.env.PUBLIC_URL + "/blogs"}
                  component={Blogs}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details"}
                  component={BlogDetailsStandard}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/return-policy"}
                  component={Return}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/privacy-policy"}
                  component={PrivacyPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/faq"}
                  component={FAQ}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/size-guide"}
                  component={SizeGuide}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
